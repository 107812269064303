// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faFacebook, faTwitter, faYoutube, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faShoppingBasket, faUser, faUserEdit, faSignOutAlt, faShoppingCart} from '@fortawesome/free-solid-svg-icons';

// add the imported icons to the library
library.add(faFacebook, faTwitter, faYoutube, faFacebookF, faShoppingBasket, faUser, faUserEdit, faSignOutAlt, faShoppingCart);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

//  Add the fontawesome icon to the shopping basket in the menu
jQuery(document).ready(function($){
$( 'li.sales a.shoppingBasket' ).append( '&nbsp;<i class="fas fa-shopping-basket"></i>' );
$( 'li.sales a.shoppingCart' ).append( '&nbsp;<i class="fas fa-shopping-cart"></i>' );
$( 'li.account a.nav-link' ).append( '&nbsp;<i class="fas fa-user"></i>' );
$( 'li.account a.dash' ).append( '&nbsp;<i class="fas fa-user-edit"></i>' );
$( 'li.account a.out' ).append( '&nbsp;<i class="fas fa-sign-out-alt"></i>' );
});
